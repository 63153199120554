import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"

const Footer = () => {
  return (
    <React.Fragment>
      <Box
        className="footer"
        style={{
          position: "absolute",
          width: "100%",
          maxHeight: 80,
          padding: "12px 0px",
        }}
      >
        <Grid
          container
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: "12px 8px",
            color: "#FFF",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="body1" color="inherit" style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <strong>BARIAMED ©</strong><i style={{fontSize: 12, marginLeft: 8}}> Last Update - 17/12/2022</i>
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ paddingRight: 20, cursor: "pointer" }}
            >
              <a href="/about" style={{ color: "inherit" }}>
                A Propos
              </a>
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              style={{ cursor: "pointer" }}
            >
              <a
                href="mailto:bariamed@hotmail.com?subject=BARIAMED Informations"
                style={{ color: "inherit" }}
                target="_blank"
              >
                Contact
              </a>
            </Typography>
          </div>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
export default Footer
