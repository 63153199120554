import generalities_icon from "./images/generalities_icon.svg"
import gestion_icon from "./images/gestion_icon.svg"
import hospital_icon from "./images/hospital_icon.svg"
import pregnant_icon from "./images/pregnant_icon.svg"
import suivi_icon from "./images/suivi_icon.svg"
import supplement_icon from "./images/supplement_icon.svg"
import surgery_icon from "./images/surgery_icon.svg"

export interface Service {
  id: number
  image: string
  title: string
  description: string
  link: string
  linkText: string
}

export const services: Service[] = [
  {
    id: 1,
    image: generalities_icon,
    title: "Généralités",
    description: "Sur la chirurgie bariatrique.",
    linkText: "Généralités",
    link: "/generalities",
  },
  {
    id: 2,
    image: gestion_icon,
    title: "Suivi en Médecine Générale",
    description: "En fonction du type de chirurgie.",
    linkText: "Suivi",
    link: "/suivi",
  },
  {
    id: 3,
    image: supplement_icon,
    title: "Supplémentations",
    description:
      "En pratique, quelle(s) supplémentation(s) réaliser de manière préventive, ou en cas de carence avérée?",
    linkText: "Supplémentations",
    link: "/supplementations",
  },
  {
    id: 4,
    image: hospital_icon,
    title: "Gestion des comorbidités",
    description: "Comment adapter les thérapeutiques?",
    linkText: "Gestion des comorbidités",
    link: "/comorbidites",
  },
  {
    id: 5,
    image: pregnant_icon,
    title: "Contraception et grossesse",
    description: "",
    linkText: "Contraception",
    link: "/contraception",
  },
  {
    id: 6,
    image: surgery_icon,
    title: "Chirurgie plastique",
    description: "Quand l’envisager et quelles modalités de prise en charge?",
    linkText: "Chirurgie plastique",
    link: "/chirurgie",
  },
  {
    id: 7,
    image: suivi_icon,
    title: "Réintervention",
    description: "Quand l’envisager?",
    linkText: "Réintervention",
    link: "/reintervention",
  },
]
