import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"
import Footer from "./footer"
import { services } from "../config"
import Box from "@material-ui/core/Box"

interface Props {
  children?: any
}

const Layout = ({ children }: Props) => {
  return (
    <React.Fragment>
      <Header services={services} />
      <Box
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 100px`,
          minHeight: "calc(100vh - 270px)",
        }}
      >
        <div style={{ marginTop: 40 }}>{children}</div>
      </Box>
      <Footer />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
